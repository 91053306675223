import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import HtmlParser from "../HtmlParser"
import LogoVFF from "../LogoVFF"

const MapBlock = () => {
  const data = useStaticQuery(graphql`
    {
      block: blockContentBasic(drupal_internal__id: { eq: 4 }) {
        info
        body {
          value
        }
      }
    }
  `)

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-4 items-center lg:items-start">
      <figure className="image w-full sm:w-1/2 mb-4 lg:mb-0">
        <img src="/footer-map.png" alt="Coucy, vert l'avenir" />
      </figure>
      <div className="w-full lg:w-1/2 lg:p-2 text-sm">
        <div className="content">
          <HtmlParser html={data.block.body.value} />
        </div>
        <div className="mt-4">
          <LogoVFF className="w-64 mx-auto" />
        </div>
      </div>
    </div>
  )
}

MapBlock.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

MapBlock.defaultProps = {
  title: "",
  titleDisplay: true,
  titleSize: 3,
}

export default MapBlock
